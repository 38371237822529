body {
  font-family: 'Open Sans', "sans-serif";
  overflow-x: hidden;
}

.line {
  height: 3px;
  background-color: inherit;
}

.box-info {
  padding-right: 50px;
  font-weight: bold;
  background-color: inherit;
  max-width: fit-content;
  margin-top: -1px;
  padding-top: -1px;
  padding-bottom: -2px;
  padding-left: 10px;
  color: white;
}

.metric-list {
  display: block;
  margin: auto;
  float: right;
  list-style-type: none;
}

.metric {
  margin-top: 2% !important;
  display: block;
  margin: auto;
}

.metric-title {
  font-weight: bold;
  margin-bottom: 5%;
}

.dropdown:hover .dropdown-content {
  display: block !important;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: aqua;
}

.no-data {
  font-family: Helvetica, "sans-serif";
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #6a6a6a;
  text-transform: capitalize;
}

.no-left-margin {
  margin-left: -30px;
}

.no-left-margin2 {
  margin-left: -10px;
}

.btn-group {
  opacity: 0.6;
  z-index: 1;
  position: fixed;
  display: inline-block;
}

.btn-group:hover {
  transition: all ease 100ms;
  opacity: 1;
}

.leftLabel {
  font-size: 13px;
  font-family: Helvetica, "sans-serif";
  position: absolute;
  margin-top: 20%;
  padding-left: 10px;
  float: left;
  transform: rotate(-90deg);
}

.bottomLabel {
  font-size: 13px;
  font-family: Helvetica, "sans-serif";
  position: absolute;
  padding-left: 43%;
  margin-bottom: -1% !important;
  bottom: 0;
}

.heatmap2 {
  display: flex;
  pointer-events: none;
}

.heatmap2-chart {
  margin-left: 10vw;
}

.mun-noLeft-margin {
  margin-left: -50px;
}

.centered {
  align-self: center !important;
}

.ins-data {
  float: right;
  color: #6a6a6a;
  margin-top: -15%;
  margin-bottom: 10%;
}

.ins-text {
  font-family: Helvetica, "sans-serif";
  font-size: 18px;
  font-weight: bold;
}

.asterisc {
  font-family: Helvetica, "sans-serif";
  font-size: 14px;
  padding-top: 10px;
}

.no-data-img {
  margin-bottom: 3%;
  width: 58%;
}

.no-data-thumb {
  margin-left: 20%;
}
